import React, {useState, useLayoutEffect} from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import ImageBase from 'gatsby-image'
import Layout from '../Layout'
import Flex from '../../components/Flex'
import Box from '../../components/Box'
import Fade from '../../components/Fade'
import SEO from '../SEO/SEO'
import Caption from '../Caption'
import FullScreenImg from './FullScreenImg'
import CustomScrollbar from './custom-scrollbar'

export const query = graphql`
  query($pageName: String!) {
    page: contentfulPageWithGallery(pageName: { eq: $pageName }) {
      pageName
      images {
        description
        title
        fluid(quality:100, maxWidth: 2400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
const ImageContainer = styled(Box)`
  ${props => `width: ${props.columnSize}`};
`

const Image = styled(ImageBase)`
  width: 100%;
`

const getColumnSize = (col='|8|')=>{
  const column = parseInt(col.split('|').join(''))
  return `${(column || 0.66) * 100/12}%`
}

const PageWithGalleryTemplate = ({ data }) => {
  const [fullScreenItem, setFullScreenItem]=useState(null)
  const [lastScroll, setLastScroll]=useState(null)

  useLayoutEffect(() => {
    if(!fullScreenItem){
      window && window.scroll(0,lastScroll)
    } else {
      window && window.scroll(0, 0)
    }
  }, [fullScreenItem])

  const { page } = data

  const onItemClick = (img, onSlideChange)=>{
    const lastScroll = window ? window.scrollY : 0
    setLastScroll(lastScroll)
    setFullScreenItem(img)
    onSlideChange(img)
  }

  const closeActiveItem = (onSlideChange)=> ()=> {
    setFullScreenItem(null)
    onSlideChange(null)
  }
  return (
    <>
      <CustomScrollbar />
      <SEO title={page.pageName} />
      <Layout>
        {({onSlideChange})=>(
          <Fade>
            <Box display={['none', 'none', 'block']}>
              {fullScreenItem ? (
                <FullScreenImg closeActiveItem={closeActiveItem} onSlideChange={onSlideChange} fullScreenItem={fullScreenItem} />
              ) : (
                <Flex mt='130px' justifyContent='space-around' alignItems='center' flexWrap='wrap' width='100%'>
                  {page.images.map(img=>{
                    return (
                      <ImageContainer onClick={()=> onItemClick(img, onSlideChange)} cursor='pointer' columnSize={getColumnSize(img.description)} mb='128px' key={img.title}>
                        <Image fluid={img.fluid} alt={img.title} />
                      </ImageContainer>)
                  })}
                </Flex>
              )}
            </Box>
            <Box display={['block', 'block', 'none']}>
              {page.images.map((img, i)=>{
                return (
                  <Box key={img.title + i} px='16px'>
                    <ImageContainer key={img.title}>
                      <Image fluid={img.fluid} alt={img.title} />
                    </ImageContainer>
                    <Caption image={img}  />
                  </Box>)
              })}
            </Box>
          </Fade>
        )}
      </Layout>
    </>
  )
}


export default PageWithGalleryTemplate
