import React from 'react'
import styled from 'styled-components'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
import ImageBase from 'gatsby-image'
import './custom-scrollbar.js'

const Image = styled(ImageBase)`
  width: 100%;
`
const ImageContainer = styled(Box)`
  ${props => `width: ${props.columnSize}`};
`

const FullScreenImg = ({closeActiveItem, onSlideChange, fullScreenItem}) => (
  <Box onClick={closeActiveItem(onSlideChange)}>
    <Flex alignItems='center' height='100vh' position='relative'>
      <Box cursor='pointer' flex={0.25/fullScreenItem.fluid.aspectRatio} px='16px'  />
      <ImageContainer flex={fullScreenItem.fluid.aspectRatio} key={fullScreenItem.title}>
        <Image fluid={fullScreenItem.fluid} alt={fullScreenItem.title} />
      </ImageContainer>
      <Box cursor='pointer' flex={0.25/fullScreenItem.fluid.aspectRatio} px='16px' />
    </Flex>
  </Box>
)

export default FullScreenImg