import { createGlobalStyle } from 'styled-components'

const CustomScrollbar = createGlobalStyle`
  body {
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    
    &::-webkit-scrollbar-thumb {
      background: #888; 
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
  }
`

export default CustomScrollbar